import { Box, Container, Typography } from '@mui/material';
import React from 'react';

const CancelPolicy = () => {
  return (
    <Box my={6}>
      <Container>
        <Typography sx={{ textAlign: 'center' }} variant='h3' mb={4}>Cancellation Policy</Typography>
        <Typography sx={{ color: '#0D204A' }}>
          After hours rates apply to reservations outside of local business
          hours. Contact your account manager for details.
          <br />
          You can cancel up to{' '}
          <span style={{ textDecoration: 'underline' }}>48 hours</span> before
          the reservation for a full refund. Cancellations{' '}
          <span style={{ textDecoration: 'underline' }}>
            within 48 hours are refunded 50%,
          </span>{' '}
          and{' '}
          <span style={{ textDecoration: 'underline', color: '#F5222D' }}>
            cancellations within 24 hours are non-refundable
          </span>
        </Typography>
      </Container>
    </Box>
  );
};

export default CancelPolicy;
