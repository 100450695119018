import React from 'react';
import WithLayout from 'WithLayout';
import { Main } from 'layouts';
import { graphql } from 'gatsby';
import CancelPolicy from 'views/CancelPolicy';

const CancelPolicyPage = (): JSX.Element => {
  return <WithLayout component={CancelPolicy} layout={Main} />;
};

export default CancelPolicyPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

